<nz-alert
  nzType="error"
  nzCloseable
  nzMessage="This site is in BETA"
  [nzDescription]="alertDescription"
></nz-alert>

<ng-template #alertDescription>
  DPS sim for SCH based on the original Google sheets sim is available: change stat values, party comp, and rotation info to see the effect on DPS.<br/>
  Changes you make will NOT persist if you close the browser tab.<br/>
  Future updates will allow gearsets to be saved/loaded, custom rotations, support for additional jobs, and more.<br/>
  If you notice any issues or have feedback, please use the button in the lower left to visit us on GitHub, check to see if your issue is already tracked, and file one if it's not. Thanks!<br/>
</ng-template>