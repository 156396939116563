<nz-layout>
  <nz-header></nz-header>
  <nz-content class="app-body">
  	<app-notice></app-notice>
    <div class="party-select">
    	<app-party-select></app-party-select>
    </div>
    <app-rotation-options></app-rotation-options>
    <app-gearset-list></app-gearset-list>
  </nz-content>
  <nz-footer>
  	<ntkme-github-button
	  user="axheron"
	  repo="ffxiv-dps-calc"
	  [standardIcon]=true
	  size="large"
	  type="issue"
	  >
    </ntkme-github-button>
  </nz-footer>
</nz-layout>
