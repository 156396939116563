<div class="party-select-container" role="group">
  <button type="button" (click)="changeSelf()" class="self-icon"
        [attr.aria-label]="'Player: ' + jobConsts.JOBS.get(dpsService.selfJob)!.name">
	  <nz-avatar [nzSize]="56"
	      nzSrc="{{jobConsts.JOBS.get(dpsService.selfJob)!.path}}">
	  </nz-avatar>
  </button>
  <button *ngFor="let member of dpsService.party; index as i;"
        type="button" (click)="changeParty(i)" class="party-member-icon"
        [attr.aria-label]="'Party: ' + jobConsts.JOBS.get(member)!.name">
      <nz-avatar [nzSize]="56"
          nzSrc="{{jobConsts.JOBS.get(member)!.path}}">
	  </nz-avatar>
  </button>
</div>
<div class="buffs-container">
  <img class="buff-icon"
      *ngFor="let buff of jobConsts.JOBS.get(dpsService.selfJob)!.buffs;"
      alt="{{buffs.get(buff)!.name}}"
      src="{{buffs.get(buff)!.path}}"/>
  <ng-container *ngFor="let member of dpsService.party;">
    <img class="buff-icon"
	    *ngFor="let buff of jobConsts.JOBS.get(member)!.buffs;"
	     alt="{{buffs.get(buff)!.name}}"
		src="{{buffs.get(buff)!.path}}"/>
  </ng-container>
</div>
<ng-template #iconTemplate>
  <i nz-icon nzType="stop" class="ant-scroll-number-custom-component" style="color: #f5222d;"></i>
</ng-template>
<nz-modal #jobPickerModal [(nzVisible)]="jobPickerVisible" [nzFooter]="null"
    (nzOnCancel)="closeJobPicker()" [nzAutofocus]="null"
    [nzTitle]="'Select a job'">
  <ng-container *nzModalContent>
  	<ng-container *ngFor="let role of selectableRoles">
      <div class="role-container">
        <div class="role-name">{{role.name}}</div>
        <button *ngFor="let job of role.jobs;"
            type="button" class="party-member-icon" (click)="pickJob(job.id)"
            [disabled]="hideUnimplementedJobs && !jobConsts.IMPLEMENTED_JOBS.includes(job)"
            [attr.aria-label]="job.name">
        <nz-badge *ngIf="hideUnimplementedJobs && !jobConsts.IMPLEMENTED_JOBS.includes(job)" [nzCount]="iconTemplate">
          <nz-avatar [nzSize]="64" nzSrc="{{job.path}}"></nz-avatar>
        </nz-badge>
        <nz-avatar *ngIf="!hideUnimplementedJobs || jobConsts.IMPLEMENTED_JOBS.includes(job)" [nzSize]="64" nzSrc="{{job.path}}"></nz-avatar>
        </button>
      </div>
    </ng-container>
  </ng-container>
</nz-modal>
