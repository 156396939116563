<nz-table #basicTable class="gearset-table" [nzData]="dpsService.dataSet"
    nzShowPagination=false>
  <thead>
    <tr>
      <th rowspan="2">Set name</th>
      <th colspan="7">Stats</th>
      <th rowspan="2">GCD</th>
      <th rowspan="2">MP/min</th>
      <th rowspan="2">DPS</th>
      <th rowspan="2">Action</th>
    </tr>
    <tr>
      <th>WD</th>
      <th>MND</th>
      <th>DH</th>
      <th>Crit</th>
      <th>Det</th>
      <th>SpS</th>
      <th>Pie</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of basicTable.data">
      <ng-container *ngIf="!editCache[data.id].edit; else editTemplate">
        <td class="name-col">
          <ng-container *ngIf="!data.etroId">
            {{data.name}}
          </ng-container>
          <ng-container *ngIf="data.etroId">
            <a href="https://etro.gg/gearset/{{data.etroId}}" target="_blank">
              {{data.name}}
            </a>
          </ng-container>
        </td>
        <td class="num-col">{{data.weaponDamage}}</td>
        <td class="num-col">{{data.mainStat}}</td>
        <td class="num-col">{{data.dh}}</td>
  	    <td class="num-col">{{data.crit}}</td>
  	    <td class="num-col">{{data.det}}</td>
	      <td class="num-col">{{data.speed}}</td>
        <td class="num-col">{{data.pie}}</td>
        <td class="num-col">{{data.gcd}}</td>
        <td class="num-col">{{data.mp}}</td>
        <td class="num-col">{{data.dps | floor}}</td>
        <td class="action-col">
          <button (click)="startEdit(data.id)" class="gearset-table-button">
            <a>Edit</a>
          </button>
        </td>
      </ng-container>
      <ng-template #editTemplate>
        <td class="name-col">
          <input class="name-edit-input" type="text" nz-input
              aria-label="Gearset name"
        	    [(ngModel)]="editCache[data.id].data.name" />
          <input type="text" nz-input
              aria-label="Etro id"
              [(ngModel)]="editCache[data.id].data.etroId" />
        </td>
        <td class="num-col">
          <input type="number" nz-input
              aria-label="Weapon damage"
              [(ngModel)]="editCache[data.id].data.weaponDamage" />
        </td>
        <td class="num-col">
          <input type="number" nz-input
              aria-label="Main stat"
              [(ngModel)]="editCache[data.id].data.mainStat" />
        </td>
        <td class="num-col">
          <input type="number" nz-input
              aria-label="Direct hit"
              [(ngModel)]="editCache[data.id].data.dh" />
        </td>
        <td class="num-col">
          <input type="number" nz-input
              aria-label="Crit"
              [(ngModel)]="editCache[data.id].data.crit" />
        </td>
        <td class="num-col">
          <input type="number" nz-input
              aria-label="Determination"
              [(ngModel)]="editCache[data.id].data.det" />
        </td>
        <td class="num-col">
          <input type="number" nz-input
              aria-label="Spell speed"
              [(ngModel)]="editCache[data.id].data.speed" />
        </td>
        <td class="num-col">
          <input type="number" nz-input
              aria-label="Piety"
              [(ngModel)]="editCache[data.id].data.pie" />
        </td>
        <td class="num-col">
          {{data.gcd}}
        </td>
        <td class="num-col">{{data.mp}}</td>
        <td class="num-col">{{data.dps | floor}}</td>
        <td class="action-col">
          <button (click)="saveEdit(data.id)" class="gearset-table-button save">
            <a>Save</a>
          </button>
          <br>
          <button (click)="cancelEdit(data.id)" class="gearset-table-button">
            <a>Cancel</a>
          </button>
        </td>
      </ng-template>
    </tr>
  </tbody>
</nz-table>
